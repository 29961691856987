import {observer} from "mobx-react";
import MDBox from "../../../components/MDBox";
import AdminLayout from "../../../components/AdminLayout";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {useParams, useSearchParams} from "react-router-dom";
import ItemsToBuyList from "./ItemsToBuyList";
import {useEffect, useState} from "react";
import {showMessage, useApi} from "../../../services/helpers";
import {useStores} from "../../../models";
import {Card, Grid, Icon} from "@mui/material";
import OrderHeaderCard from "../../project-manager/orders-and-quotes/products-card-and-details/OrderHeaderCard";
import {FixedDrawer} from "../../project-manager/orders-and-quotes/fixed-drawer";
import * as React from "react";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import {FilecardContainer, FilenameText} from "../manage-products/manage_product/styles";

const CutSheetsTab = ({order}) => {
  const api = useApi()

  const documents = {
    'Signed Contract': order?.signed_contract,
    'Delivery Schedule': order?.delivery_schedule,
    'Manufacturer Contract': order?.manufacturer_contract,
  }
  const has_some_document = Object.values(documents).some((document) => document)


  const downloadAllCutsheets = () => {
    // setLoading(true)
    api.downloadOrderCutsheets(null, order?.id).then((result) => {
      // setLoading(false)
      if (result.kind === 'ok') {
        result.download_file()
      }else{
        throw new Error()
      }
    }).catch((reason) => {
      // setLoading(false)
      showMessage()
    })
  }

  const downloadFile = (url) => {
    window.open(url, '_blank')
  }

  return (
    <Card sx={styles.cardContainer}>
      <MDBox
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'start'}>
        <MDTypography sx={{
          ...styles.titles,
          // borderTop:  "1px solid #DBDBDB",
          //pb: pxToRem(22)
        }}
        >
          Cut Sheets
        </MDTypography>
        <MDButton
          variant="outlined"
          color="secondary"
          onClick={downloadAllCutsheets}
        >
          Download All
        </MDButton>
      </MDBox>
      <MDBox
        display={'flex'}
        flexDirection={'column'}
        // justifyContent={'space-between'}
        alignItems={'start'}>
        {order?.cutsheets?.map((cutSheet) => (
            <MDBox sx={FilecardContainer} key={cutSheet.id}>
              <MDBox display='flex' alignItems='center'>
                <Icon sx={(th) => ({ml: pxToRem(11), mr: 2})} fontSize='medium'>attachment</Icon>
                <MDTypography sx={FilenameText}>
                  {cutSheet.cut_sheet_file?.name || cutSheet?.cut_sheet_file_name}
                </MDTypography>
              </MDBox>
              <MDTypography
                sx={{textDecoration: 'underline', mr: pxToRem(10), cursor: 'pointer'}}
                variant='button'
                fontWeight='regular'
                color='tertiary'
                onClick={() => downloadFile(cutSheet.cut_sheet_file)}>
                Download
              </MDTypography>
            </MDBox>
          )
        )}
      </MDBox>
      {has_some_document && <MDBox
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'start'}>
        <MDTypography sx={{
          ...styles.titles,
          // borderTop:  "1px solid #DBDBDB",
          //pb: pxToRem(22)
        }}
        >
          Documents
        </MDTypography>
      </MDBox>}
      <MDBox
          display={'flex'}
          flexDirection={'column'}
          // justifyContent={'space-between'}
          alignItems={'start'}>
        {Object.entries(documents).map(([docName, doc], index) => doc ? (
                <MDBox sx={FilecardContainer} key={`docs-${index}`}>
                  <MDBox display='flex' alignItems='center'>
                    <Icon sx={(th) => ({ml: pxToRem(11), mr: 2})} fontSize='medium'>attachment</Icon>
                    <MDTypography sx={FilenameText}>
                      {docName}
                    </MDTypography>
                  </MDBox>
                  <MDTypography
                      sx={{textDecoration: 'underline', mr: pxToRem(10), cursor: 'pointer'}}
                      variant='button'
                      fontWeight='regular'
                      color='tertiary'
                      onClick={() => downloadFile(doc)}>
                    Download
                  </MDTypography>
                </MDBox>
            ) : null
        )}
      </MDBox>
    </Card>
  )
}

const OrderMainPage = () => {
  const api = useApi()
  const rootStore = useStores();
  const {loginStore, projectStore} = rootStore;
  const { id: orderId} = useParams()
  const [searchParams ] = useSearchParams();
  const version_id = searchParams.get('version');
  const [order, setOrder] = useState(null)
  const type = order?.is_order ? 'Order': 'Quote'
  const [tabVisible, setTabVisible] = useState(0);
  const isNotAdmin = (loginStore.isProjectManager || loginStore.isSupplyManager);

  const updateOrder = () => {
    api.getOrderDetail(null, orderId, version_id).then((result) => {
      if (result.kind === 'ok') {
        // result.data.is_editable = true
        setOrder(result.data)
      }else{
        throw new Error()
      }
    }).catch((error) => {
      showMessage('Error getting order details')
    })
  }

  useEffect(() => {
    if (!orderId)
      return
    updateOrder()
  }, [orderId, version_id])

  return (
    <AdminLayout title={'Order Management'} >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={isNotAdmin ? 8 : 12}>
          <MDBox sx={styles.mainContainer}>
            {order && <>
              <OrderHeaderCard
                key={order?.id}
                order={order}
                orderTotal={order?.total}
                projectManager={order?.buyer}
                project={order.project.name}
                projectId={order.project.id}
                status={order.status}
                manufacturer={order.manufacturer?.name}
                manufacturerImage={order.manufacturer}
                onOrderUpdated={updateOrder}
                location={order.location}
              />
              <ItemsToBuyList
                order={order}
                onOrderUpdated={updateOrder}
                onTabChanged={(tab) => setTabVisible(tab)}
              />

              {tabVisible === 1 && <CutSheetsTab order={order} /> }

            </>}
          </MDBox>
        </Grid>
        {
          isNotAdmin &&
          <Grid item xs={12} lg={4}>
            <FixedDrawer order={order} onOrderUpdated={updateOrder}/>
          </Grid>
        }
      </Grid>

    </AdminLayout>
  )
}

export default observer(OrderMainPage);
const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    mr: pxToRem(20),
    // gap: pxToRem(20),
    width: "100%",
    borderRadius: pxToRem(16)
  },
  cardContainer: {
    p: pxToRem(28)
  },
  titles: {
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(21),
    pt: pxToRem(20),
    pb: pxToRem(12),
  },
  cardStyle: {
    marginTop: pxToRem(20),
  }
}
