import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import * as React from "react";
import {Grid} from "@mui/material";

export const FreshOrderButtons = ({handleA, handleB, loading, disabled}) => (
    <Grid container pt={2} spacing={2}>
        <Grid item xs={12} lg={6}>
            <MDButton variant="outlined" color="secondary" sx={{ backgroundColor: '#fff', '&:hover': {backgroundColor: '#fff'}, width: '100%' }} disabled={loading || disabled} onClick={handleB}>Delete Cart</MDButton>
        </Grid>
        <Grid item xs={12} lg={6}>
            <MDButton loading={loading} color={'secondary'} sx={{ width: '100%' }} disabled={loading || disabled} onClick={handleA}>Summit Order</MDButton>
        </Grid>
    </Grid>
)


export const FreshOrderVendorButtons = ({handleA, handleB, loading}) => (
    <Grid container pt={2} spacing={2}>
        <Grid item xs={12} lg={6}>
            <MDButton variant="outlined" color="secondary" sx={{ backgroundColor: '#fff', '&:hover': {backgroundColor: '#fff'}, width: '100%' }} disabled={loading} onClick={handleB}>Reject Quote</MDButton>
        </Grid>
        <Grid item xs={12} lg={6}>
            <MDButton loading={loading} color={'secondary'} sx={{ width: '100%' }} disabled={loading} onClick={handleA}>Approve Quote</MDButton>
        </Grid>
    </Grid>
)

export const LockedOrderButtons = ({handleA, handleB, loading, lockedByMe}) => (
    <Grid container pt={2} spacing={2}>
        <Grid item xs={12} lg={6}>
            <MDButton variant="outlined" color="secondary" sx={{ backgroundColor: '#fff', '&:hover': {backgroundColor: '#fff'}, width: '100%' }} loading={loading} disabled={loading} onClick={handleB}>Edit Cart</MDButton>
        </Grid>
        <Grid item xs={12} lg={6}>
            {!lockedByMe && <MDButton loading={loading} color={'secondary'} sx={{ width: '100%' }} disabled={loading} onClick={handleA}>Approve Order</MDButton>}
        </Grid>
    </Grid>
)

export const QuoteButtons = ({handleA, handleB, loading, disabled}) => (
    <Grid container pt={2} spacing={2}>
        <Grid item xs={12} lg={6}>
            <MDButton variant="outlined" color="secondary" sx={{ backgroundColor: '#fff', '&:hover': {backgroundColor: '#fff'}, width: '100%' }} disabled={loading || disabled} onClick={handleB}>Delete Cart</MDButton>
        </Grid>
        <Grid item xs={12} lg={6}>
            <MDButton loading={loading} color={'secondary'} sx={{ width: '100%' }} disabled={loading || disabled} onClick={handleA}>Share Cart</MDButton>
        </Grid>
    </Grid>
)
