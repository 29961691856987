import DashboardProjectManagerLayout from "components/DashboardProjectManager";
import {observer} from "mobx-react";
import DataTable from "../../../components/DataTable";
import MDBox from "../../../components/MDBox";
import {dataTableModel} from "./utils";
import {useEffect, useState} from "react";
import {showMessage, useApi} from "../../../services/helpers";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import MDButton from "../../../components/MDButton";
import {useNavigate} from "react-router-dom";
import {ROLES, ROUTES} from "../../../services/constants";
import SearchBar from "../../../components/SearchBar";
import {useStores} from "../../../models";
import OneSignal from "react-onesignal";
import shop_button from "./../../../assets/images/button/shop_button.JPG";

function ProjectsPage({selectedButtonId}) {
  const api = useApi();
  const navigate = useNavigate();
  const rootStore = useStores()
  const {loginStore} = rootStore
  const [loading, setLoading] = useState(false);
  const [datatable, setDatatable] = useState({...dataTableModel});
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [numberOfItemsPage, setNumberOfItemsPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProject, setSelectedProject] = useState(null);

  const getAllProjects = (searchData = '', page = 1, ordering = '') => {
    setLoading(true)
    api.getProjects({search: searchData, page, ordering}).then((result) => {
      if (result.kind === "ok") {
        const {count, results} = result.data
        const tmp = {...dataTableModel}
        tmp.rows = results.map(e => renderTableRow(e))
        setDatatable(tmp)
        setNumberOfItems(count)
        setNumberOfItemsPage(results.length)
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }


  const getShoppingCartItems = (projectId) => {
    setLoading(true)
    api.getShoppingCartQuantity(projectId).then((result) => {
      if (result.kind === "ok") {
        const {quantity} = result.data
        console.log("quantity",quantity)
        loginStore.setShoppingCartQuantity(quantity)
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }
  const handleGoToProject = (project) => {
    if (project?.id) {
      getShoppingCartItems(project.id)
      if (loginStore.group === ROLES.SUPPLY_MANAGER.name) {
        navigate(ROUTES.USER_SHOPPING_CART(project?.id))
      } else {
        navigate(ROUTES.USER_PROJECT_SELECTED(project?.id))
      }

    } else if (project === null) {
      showMessage("Please select a project", 'warning')
    }
  }
  const renderTableRow = (item) => {
    item.style = [{backgroundColor: '#BEF183'}]
    item.company__name = item.company.name
    item.region__name = item.region.name
    item.brand__name = item.brand.name
    return item
  }

  // useEffect(() => {
  //   runOneSignal();
  // })


  const initOneSignal = async () => {
    try {
      await OneSignal.init({appId: "dd3adfa1-205f-4f12-b994-748077112a0a"})
      OneSignal.showNativePrompt();
      const pushNotification = await OneSignal.isPushNotificationsEnabled()
      console.log('pushNotification', pushNotification)
      const userId = await OneSignal.getUserId()
      if (userId) {
        const {data} = await api.setDevice({user_id: userId, push_token: '', active: true})
        console.log('data', data)
      }
      console.log('userId', userId)
      const subscription = await OneSignal.getSubscription()

      console.log('subscription', subscription)
      const notificationPermission = await OneSignal.getNotificationPermission()
      console.log('notificationPermission', notificationPermission)
      const isPushNotificationsEnabled = await OneSignal.isPushNotificationsEnabled()
      console.log('isPushNotificationsEnabled', isPushNotificationsEnabled)

      OneSignal.on('subscriptionChange', () => {

      });
    } catch (error) {
      console.log('error', error)
    }

  }

  useEffect(() => {
    initOneSignal()
  },[])

  return (
    <DashboardProjectManagerLayout
      justifyContent={"space-between"}
      title={'Select Project'}
      selectedButtonId={selectedButtonId}
      header={
        <SearchBar loading={loading} search={getAllProjects} setSearchQuery={setSearchQuery}/>
      }
    >
      <MDBox sx={{overflow:"visible", width:"100%" }}>
      <DataTable
        loading={loading}
        loadingText={'Loading...'}
        table={datatable}
        currentPage={currentPage}
        numberOfItems={numberOfItems}
        numberOfItemsPage={numberOfItemsPage}
        setSelectedProject={setSelectedProject}
        selectedProject={selectedProject}
        searchFunc={getAllProjects}
        searchQuery={searchQuery}
        onPageChange={page => {
          getAllProjects(searchQuery, page)
          setCurrentPage(page)
        }}
      />
      </MDBox>
      <MDBox width={'100%'} display={'flex'} mt={3}>
        <MDBox width={'100%'} display={'flex'} justifyContent={'flex-end'}>
          <MDButton color={'secondary'} sx={{width: pxToRem(280)}} onClick={() => handleGoToProject(selectedProject)}>
            Go To Project
          </MDButton>
        </MDBox>
      </MDBox>
      { loginStore.is_alliance_employee &&
        <MDBox
          component={"img"}
          src={shop_button}
          alt={"shop"}
          width={pxToRem(400)}
          height={pxToRem(200)}
          borderRadius={"5%"}
          sx={{border: "1px solid", cursor: "pointer"}}
          onClick={() => window.open(loginStore.shopify_link, "_blank")}>
        </MDBox>
      }
    </DashboardProjectManagerLayout>
  );
}

export default observer(ProjectsPage);
