import {observer} from "mobx-react";
import {Box, Card, Grid} from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import {money_fmt, useIsMobile} from "../../../../services/helpers";
import logo from "assets/icons/dolovo-D.png";
import MDBox from "../../../../components/MDBox";
import DrawerRight from "../../../../components/DrawerRight/DrawerRight";
import {useStores} from "../../../../models";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import { useEffect, useState } from "react";
import { UpdateManagerModal } from "../modals/UpdateManagerModal";

const OrderHeaderCard = (props) => {
  const isMobile = useIsMobile()
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const {
    manufacturer,
    orderTotal,
    projectManager,
    project,
    projectId,
    onOrderUpdated,
    location,
    status,
    order,
  } = props;

  const statusRename = (order) => {
    if (order?.order_phase === "Quote" || order?.order_phase === "Collaboration")
      if (order?.tasks.vendor_approve_order && !order?.tasks.approve_order)
        return 'Pending Buyer Approval'
      else if (!order?.tasks.vendor_approve_order && order?.tasks.approve_order)
        return 'Pending Supplier Acceptance'
      else if (!order?.tasks.approve_order && !order?.requested)
        return 'Shopping Cart Created'
      else if (!order?.tasks.approve_order && order?.requested)
        return 'Quote/Cart Requested'
    return order?.status
  }

  const [openAddManagerModal, setOpenAddManagerModal] = useState(false);
  const [orderProjectManager, setOrderProjectManager] = useState(projectManager);
  const isNotAdmin = !(loginStore.isProjectManager || loginStore.isSupplyManager);

  const handlePMClicked = ()=> {
    if (loginStore.isSubContractorRep || loginStore.isSupplyManager) return
    setOpenAddManagerModal(true)
  }

  const onManagerUpdate = (manager) => {
    setOrderProjectManager(manager)
  }

  return (<>
    {openAddManagerModal &&
      <UpdateManagerModal
        open={openAddManagerModal}
        order={order}
        currentPM={orderProjectManager} // for some reason, prop 'projectManager' is an array of 1 object
        onUpdate={onManagerUpdate}
        onClose={() =>setOpenAddManagerModal(false)}
      />
    }
    <Card>
      {isNotAdmin &&
        <MDBox>
          <MDTypography variant={'h6'} color={'primary'} fontSize={'14px'} p={2} pb={0}>Order {props?.order.id} - Alliance Residential</MDTypography>
          <MDBox display={'flex'} justifyContent={'space-between'} px={2}>
            <MDTypography variant={'h5'}>Appliances</MDTypography>
            <MDBox display={'flex'} justifyContent={'space-between'} width={250}>
              <MDTypography variant={'h5'}>Order Stage</MDTypography>
              <DrawerRight order={props?.order} onOrderUpdated={onOrderUpdated} projectId={projectId} />
            </MDBox>
          </MDBox>
        </MDBox>
      }
      <Grid container direction="row" justifyContent="space-between">
        <Grid  item md={2} borderRight={'1px solid #DBDBDB'} sx={isMobile ? {p:"8px"} : {p:"16px"} } >
          <MDTypography variant={'subtitle2'} fontSize={'12px'}>Vendor</MDTypography>
          <Grid container direction={"row"} flexWrap={"nowrap"} justifyContent={'flex-start'} gap={0.5} alignItems={'center'}>
          <Grid item md={3}>
            <Box sx={{maxWidth:"24px", minHeight:"24px"}}>
            <Box
                component={"img"}
                src={props?.order.manufacturer?.logo ? props?.order.manufacturer?.logo : logo}
                alt={"logo"}
                sx={{ width: "24px", height: "24px", borderRadius: 12, objectFit:"cover" }}
            />
          </Box>
          </Grid>
            <Grid item md={9} >
            <MDTypography variant={'h3'} fontSize={'14px'} sx={{textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",}}>{manufacturer}</MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid onClick={handlePMClicked} item md={2}  borderRight={'1px solid #DBDBDB'} sx={{p:isMobile ? "8px" : "16px", cursor: "pointer"}} >
            <MDTypography variant={'subtitle2'} fontSize={'12px'}>Buyer</MDTypography>
          <Grid container direction={"row"}  flexWrap={"nowrap"}  justifyContent={'flex-start'} gap={0.5} alignItems={'center'}>
            {
              <MDBox display={'flex'} justifyContent={'flex-start'} flexDirection={'row'} key={'ids-'+ orderProjectManager.id} alignitems={'center'} sx={{minWidth:pxToRem(60), alignItems:"center"}}>
                <Grid item md={3}>
                <Box
                  component={"img"}
                  src={orderProjectManager.profile_picture ? orderProjectManager.profile_picture : logo}
                  alt={""}
                  sx={{ width: "24px", height: "24px", borderRadius: 12, objectFit:"cover"}}
                /></Grid>
                <Grid item md={9}>
                  <MDTypography variant={'h3'} fontSize={'14px'} key={orderProjectManager.id} sx={{ marginLeft: 1, }}>{orderProjectManager.name}</MDTypography>
                </Grid>
              </MDBox>
            }
          </Grid>
        </Grid>
        <Grid item md={2} borderRight={'1px solid #DBDBDB'} sx={isMobile ? {p:"8px"} : {p:"16px"} } >

          <MDTypography variant={'subtitle2'} fontSize={'12px'}>Project</MDTypography>
          <Grid item md={12}>
          <MDTypography variant={'h3'} color={'primary'} fontSize={'14px'}>{project}</MDTypography>
          </Grid>
        </Grid>
        <Grid item md={2} borderRight={'1px solid #DBDBDB'}  sx={isMobile ? {p:"8px"} : {p:"16px"} } >
          <MDTypography variant={'subtitle2'} fontSize={'12px'}>Location</MDTypography>
          <Grid item md={12}>
          <MDTypography variant={'h3'} fontSize={'14px'}>{location}</MDTypography>
          </Grid>
        </Grid>
        <Grid item md={2} borderRight={'1px solid #DBDBDB'}   sx={isMobile ? {p:"8px"} : {p:"16px"} }>
          <MDTypography variant={'subtitle2'} fontSize={'12px'}>Status</MDTypography>
          <Grid item md={12}>
          <MDTypography variant={'h3'} fontSize={'14px'}>{statusRename(order)}</MDTypography>
          </Grid>
        </Grid>
        <Grid item md={2} sx={isMobile ? {p:"8px"} : {p:"16px"} }>
          <MDTypography variant={'subtitle2'} fontSize={'12px'}>Order Total</MDTypography>
          <Grid item md={12}>
          <MDTypography variant={'h3'} fontSize={'14px'}>{money_fmt(orderTotal)}</MDTypography>
          </Grid>
        </Grid>
      </Grid>
      {isNotAdmin &&
        <MDTypography variant={'subtitle2'} fontSize={'12px'} p={2} pt={0.5}>{props?.order.friendly_status}</MDTypography>
      }
    </Card>
  </>)
}

export default observer(OrderHeaderCard);
