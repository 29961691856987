import { observer } from "mobx-react";

// Components
import MDBox from "../../../components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import MDTypography from "../../../components/MDTypography";
import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import { useEffect, useState } from "react";
import MDButton from "../../../components/MDButton";
import { showMessage, useApi, useLoginStore } from "../../../services/helpers";
import { useParams } from "react-router-dom";
import { OrderSummaryCard } from "./products-card-and-details/OrderSummaryCard";
import { Container, Input } from "../../../components/FiltersBar/styles";
import { Autocomplete, CircularProgress, Grid, InputAdornment, TextField } from "@mui/material";
import search from "../../../assets/icons/search.svg";
import Pagination from "../../../components/DataTable/Pagination/Pagination";
import {ORDER_PHASES} from "../../../services/constants";

export const OrdersAndQuotesBase = observer(({statusList, selectedButtonId}) => {
  const api = useApi();
  const loginStore = useLoginStore();
  const { id: idProject } = useParams();
  const [archived, setArchived] = useState(false);
  const [subContractorsOnQ, setSubContractorsOnQ] = useState(false);
  const [orders, setOrders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
  });
  const [loading, setLoading] = useState(true);
  const [isSelected, setIsSelected] = useState(null);

  const getOrders = (data) => {

    api
      .getOrders(idProject, { ...data })
      .then((result) => {
        if (result.kind === "ok") {
          const { count, results } = result.data;
          setOrders(results);

          setPaginationData((value) => ({ ...value, counts: count, itemsPerPage: results.length}));
        } else {
          showMessage("Error fetching orders");
        }
      })
      .catch((error) => {
        showMessage("Error fetching orders");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getManufacturers = ({ search }) => {
    api.getManufacturers({ search, project_id: idProject }).then((result) => {
      if (result.kind === "ok") {
        setManufacturers(result.data.results);
      }
    });
  };

  const getCategories = ({ search }) => {
    api
      .getCategories({ search })
      .then((result) => {
        if (result.kind === "ok") {
          setCategories(result.data.results);
        }
      })
      .catch((reason) => console.log(reason));
  };

  const getFilters = () => {
    const selectedStatusString = statusList?.length ? statusList.join(',') : ''
    let filters = { search: searchQuery, page: 1, project_id: idProject, order_phases: selectedStatusString  };
    setPaginationData((value) => ({ ...value, currentPage: filters.page}));
    if (selectedManufacturer) {
      filters.manufacturer_id = selectedManufacturer;
    }
    if (selectedCategory) {
      filters.category_id = selectedCategory;
    }
    filters.archived = archived;
    filters.buyer_sub_contract = subContractorsOnQ;
    return filters;
  };

  useEffect(() => {
    setLoading(true);
    const selectedStatusString = statusList?.length ? statusList.join(',') : ''
    getCategories({ search: "" });
    getManufacturers({ search: "" });
    getOrders({ search: "", archived, buyer_sub_contract: subContractorsOnQ, order_phases: selectedStatusString  });
  }, []);

  useEffect(() => {
    getOrders(getFilters());
  }, [searchQuery, selectedManufacturer, selectedCategory, archived, subContractorsOnQ]);

  return (
    <DashboardProjectManagerLayout selectedButtonId={selectedButtonId}>
      <MDBox sx={styles.mainContainer}>
        <MDBox sx={{ display: "flex", justifyContent: "start", flexDirection: "row" }}>
          <MDBox
            sx={(theme) => ({
              backgroundColor: isSelected !== 1 ? "white!important" : theme.palette.button.light,
              borderRadius: "76px",
              color: theme.palette.text.bold,
              fontWeight: 600,
              height: pxToRem(46),
              padding: `${pxToRem(14)} ${pxToRem(21)}`,
              ml: pxToRem(10),
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 15,
            })}
            onClick={() => {
              setIsSelected(1);
              setArchived(false);
              setSubContractorsOnQ(false);
            }}
          >
            Active Quotes
          </MDBox>

          <MDBox
            sx={(theme) => ({
              backgroundColor: isSelected !== 2 ? "white!important" : theme.palette.button.light,
              borderRadius: "76px",
              color: theme.palette.text.bold,
              fontWeight: 600,
              height: pxToRem(46),
              padding: `${pxToRem(14)} ${pxToRem(21)}`,
              ml: pxToRem(10),
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 15,
            })}
            onClick={() => {
              setIsSelected(2);
              setArchived(true);
              setSubContractorsOnQ(false);
            }}
          >
            Archived
          </MDBox>

          {loginStore.isProjectManager && (
            <MDBox
              sx={(theme) => ({
                backgroundColor: isSelected !== 3 ? "white!important" : theme.palette.button.light,
                borderRadius: "76px",
                color: theme.palette.text.bold,
                fontWeight: 600,
                height: pxToRem(46),
                padding: `${pxToRem(14)} ${pxToRem(21)}`,
                ml: pxToRem(10),
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 15,
              })}
              onClick={() => {
                setIsSelected(3);
                setArchived(false);
                setSubContractorsOnQ(true);
              }}
            >
              Subcontractors Quotes
            </MDBox>
          )}
        </MDBox>
        {/*<FiltersBar firstParameter={'Status'} secondParameter={'Category'} placeholder={'Search for an order'} />*/}
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          alignContent={"center"}
          justifyContent={"space-between"}
          sx={Container}
        >
          {/*<MDBox sx={FilterItemModified}>*/}
          {/*  /!*<MDBox component={"img"} src={filter} alt={"filter"} width={pxToRem(16)} height={pxToRem(16)}/>*!/*/}
          {/*  /!*<MDTypography sx={FilterText}>Filters</MDTypography>*!/*/}
          {/*</MDBox>*/}
          <Grid item xs={4} sx={{ display: "flex" }}>
            <Autocomplete
              disablePortal
              options={manufacturers}
              getOptionLabel={(option) => option.name}
              sx={{ width: 300, ".MuiOutlinedInput-notchedOutline": { border: "none" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Manufacturer"
                  sx={{
                    ".MuiSvgIcon-root": {
                      color: "#0B7340",
                    },
                    ".MuiInputLabel-root": {
                      color: "#000",
                      paddingTop: "6px",
                      fontWeight: 500,
                    },
                  }}
                />
              )}
              onChange={(event, newValue) => {
                if (newValue?.id) {
                  setSelectedManufacturer(newValue?.id);
                } else {
                  setSelectedManufacturer(null);
                }
              }}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: "flex" }}>
            <Autocomplete
              disablePortal
              options={categories}
              getOptionLabel={(option) => option.name}
              sx={{ width: 300, ".MuiOutlinedInput-notchedOutline": { border: "none" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  sx={{
                    ".MuiSvgIcon-root": {
                      color: "#0B7340",
                    },
                    ".MuiInputLabel-root": {
                      color: "#000",
                      paddingTop: "6px",
                      fontWeight: 500,
                    },
                  }}
                />
              )}
              onChange={(event, newValue) => {
                if (newValue?.id) {
                  setSelectedCategory(newValue?.id);
                } else {
                  setSelectedCategory(null);
                }
              }}
            />
          </Grid>

          <Grid item xs={4} md={4} sx={{ display: "flex", paddingRight: "14px" }}>
            <TextField
              sx={Input}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <MDBox component={"img"} src={search} alt={"search"} />
                  </InputAdornment>
                ),
              }}
              onChange={(evt) => setSearchQuery(evt?.target?.value)}
              placeholder={"Search for an order"}
            />
          </Grid>
        </Grid>
        {loading && (
          <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress color="inherit" size={35} />
          </Grid>
        )}
        {orders.length
          ? orders.map((order) => (
              <OrderSummaryCard
                key={order.id}
                //orderState={order.orderState}
                order={order}
                id={order.id}
                orderTotal={order.orderTotal}
                status={order.status}
                state={order.state}
                manufacturer={order.manufacturer?.name}
                action={order.action}
              />
            ))
          : !loading && (
              <MDBox
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"500px"}
              >
                <MDTypography variant={"subtitle1"}>No items found</MDTypography>
              </MDBox>
            )}
        <Grid container mt={5}>
          <Grid item>
            <MDBox m={2} sx={{ color: "#666666", fontSize: 17, width: 300 }}>
              Showing <span style={{ color: "#000000" }}>{paginationData.itemsPerPage}</span> from{" "}
              <span style={{ color: "#000000" }}>{paginationData.counts}</span> data
            </MDBox>
          </Grid>
          <Grid item ml={"auto"}>
            <Pagination
              currentPage={paginationData.currentPage}
              totalCount={paginationData.counts}
              pageSize={10}
              onPageChange={(page) => {
                // page:page is to ensure that we have the current value in the filters
                getOrders({ ...getFilters(), page: page });
                setPaginationData((value) => ({ ...value, currentPage: page }));
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardProjectManagerLayout>
  );
});

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    mr: pxToRem(20),
    gap: pxToRem(20),
    width: "100%",
    borderRadius: pxToRem(16),
  },
  filtersContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: pxToRem(16),
    width: "100%",
    height: pxToRem(62),
  },
  filter: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid #DBDBDB",
    width: "100%",
    gap: pxToRem(6),
  },
  filterText: {
    fontSize: pxToRem(14),
    fontWeight: 500,
    lineHeight: pxToRem(17),
  },
  searchContainer: {
    padding: pxToRem(10),
    width: "100%",
  },
  search: {
    border: "1px solid #DBDBDB",
    borderRadius: pxToRem(8),
    width: pxToRem(388),
  },
  filterIcon: {
    width: pxToRem(16),
    height: pxToRem(16),
  },
  arrowIcon: {
    width: pxToRem(30),
    height: pxToRem(30),
    color: "#0B7340",
  },
};
