import { observer } from "mobx-react";

// Components
import {OrdersAndQuotesBase} from "./orderQuoteBase";
import {ORDER_PHASES} from "../../../services/constants";

export const OrdersAndQuotes = observer(({selectedButtonId}) => {

  return (
    <OrdersAndQuotesBase statusList={[
      ORDER_PHASES.CONTRACT,
      ORDER_PHASES.DELIVERY,
      ORDER_PHASES.FULFILLMENT,
      ORDER_PHASES.COMPLETE,
      ORDER_PHASES.REJECTED
    ]} selectedButtonId={selectedButtonId} />
  );
});
