import {observer} from "mobx-react";

// Components
import AdminLayout from "../../../components/AdminLayout";
import MDBox from "../../../components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import MDTypography from "../../../components/MDTypography";
import {
  Container,
  FilterItem,
  Input,
  InputContainer
} from "../../../components/FiltersBar/styles";
import {Autocomplete, CircularProgress, Grid, InputAdornment, TextField} from "@mui/material";
import search from "../../../assets/icons/search.svg";
import {useEffect, useState} from "react";
import {showMessage, useApi} from "../../../services/helpers";
import Pagination from "../../../components/DataTable/Pagination/Pagination";
import {OrderSummaryCard} from "./orderSummaryCard";
import MDButton from "../../../components/MDButton";
import {ORDER_PHASES, RENAMED_STATUS_OPTIONS} from "../../../services/constants";

const ORDER_PAGES = {
  ORDER: "Order",
  SHOPPING_CART: "Shopping Cart"
}


const Index = () => {
  const api = useApi();
  const [orders, setOrders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [regions, setRegions] = useState([]);
  const [projects, setProjects] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedOrderType, setSelectedOrderType] = useState([
    {id: ORDER_PHASES.CONTRACT, name: ORDER_PHASES.CONTRACT},
    {id: ORDER_PHASES.DELIVERY, name: ORDER_PHASES.DELIVERY,},
    {id: ORDER_PHASES.FULFILLMENT, name: ORDER_PHASES.FULFILLMENT,},
    {id: ORDER_PHASES.COMPLETE, name: ORDER_PHASES.COMPLETE,},
    {id: ORDER_PHASES.REJECTED, name: ORDER_PHASES.REJECTED},
  ]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, currentPage: 1});
  const [loading, setLoading] = useState(true)
  const [orderingByOldNew, setOrderingByOldNew] = useState(false)
  const [currentPage, setCurrentPage] = useState(ORDER_PAGES.ORDER)

  const getOrders = (data) => {
    setLoading(true)

    api.getOrders(null, {...data}).then((result) => {
      if (result.kind === 'ok') {
        const {count, results} = result.data
        setOrders(results)
        setPaginationData(value => ({...value, counts: count, itemsPerPage: results.length}))
      }else{
        showMessage('Error fetching orders')
      }
    }).catch((error) => {
      showMessage('Error fetching orders')
    }).finally(() => {
      setLoading(false)
    })
  }

  const getManufacturers = ({search}) => {
    api.getManufacturers({search,  page_size: 'all'}).then((result) => {
      if (result.kind === 'ok') {
        setManufacturers(result?.data?.results || [])
      }
    })
  }

  const getCompanies = ({search}) => {
    api.getCompanies({search,  page_size: 'all'}).then((result) => {
      if (result.kind === 'ok') {
        setCompanies(result.data.results || [])
      }
    })
  }

  const getRegions = ({search}) => {
    api.getRegions({search,  page_size: 'all'}).then((result) => {
      if (result.kind === 'ok') {
        setRegions(result.data.results || [])
      }
    })
  }

  const getProjects = ({search}) => {
    api.getProjects({search,  page_size: 'all'}).then((result) => {
      if (result.kind === 'ok') {
        setProjects(result.data.results || [])
      }
    })
  }

  const getOrdersStatusAdmin = ({search}) => {
    api.getOrdersStatusAdmin({search,  page_size: 'all'}).then((result) => {
      if (result.kind === 'ok') {
        Object.values(RENAMED_STATUS_OPTIONS).map( value => ({id: value, name: value}))
        setStatus(result.data || [])
      }
    })
  }

  const getCategories = ({search}) => {
    api.getCategories({search,  page_size: 'all'}).then((result) => {
      if (result.kind === 'ok') {
        setCategories(result.data.results || [])
      }
    }).catch(reason => console.log(reason))
  }

  const getFilters = () => {
    const selectedStatusString = selectedStatus?.length ? selectedStatus.map(e => e.name).join(',') : ''
    const orderTypeString = selectedOrderType?.length ? selectedOrderType.map(e => e.name).join(',') : ''
    let filters = {search: searchQuery, page: 1, ordering: orderingByOldNew ? '-modified' : 'modified', order_phases: selectedStatusString, order_type: orderTypeString}
    setPaginationData(value => ({...value, currentPage: filters.page}))
    if (selectedManufacturer) {
      filters.manufacturer_id = selectedManufacturer.id
    }
    if (selectedCategory) {
      filters.category_id = selectedCategory.id
    }
    if (selectedCompany) {
      filters.company_id = selectedCompany.id
    }
    if (selectedRegion) {
      filters.region_id = selectedRegion.id
    }
    if (selectedProject) {
      filters.project_id = selectedProject.id
    }
    return filters
  }

  const handleStatusChange = (value) => {
    if(currentPage === ORDER_PAGES.ORDER)
      setSelectedStatus([...value,
        {id: ORDER_PHASES.CONTRACT, name: ORDER_PHASES.CONTRACT},
        {id: ORDER_PHASES.DELIVERY, name: ORDER_PHASES.DELIVERY,},
        {id: ORDER_PHASES.FULFILLMENT, name: ORDER_PHASES.FULFILLMENT,},
        {id: ORDER_PHASES.COMPLETE, name: ORDER_PHASES.COMPLETE,},
        {id: ORDER_PHASES.REJECTED, name: ORDER_PHASES.REJECTED},
      ])
    else {
      setSelectedStatus([...value,
        {id: ORDER_PHASES.COLLABORATION,  name: ORDER_PHASES.COLLABORATION,},
        {id: ORDER_PHASES.QUOTE,  name: ORDER_PHASES.QUOTE,},
      ])
    }
  }

  const filterStatus = (value) => {
    return selectedStatus.filter(element => element.name.substring(value))
  }

  const filterItems = [
    {
      id: 1,
      options: manufacturers,
      label: 'Manufacturers',
      setItem: setSelectedManufacturer,
      multiple: false,
      optionsFilter: getManufacturers
    },
    {
      id: 2,
      options: companies,
      label: 'Companies',
      setItem: setSelectedCompany,
      multiple: false,
      optionsFilter: getCompanies
    },
    {
      id: 3,
      options: regions,
      label: 'Regions',
      setItem: setSelectedRegion,
      multiple: false,
      optionsFilter: getRegions
    },
    {
      id: 4,
      options: projects,
      label: 'Projects',
      setItem: setSelectedProject,
      multiple: false,
      optionsFilter: getProjects
    },
    {
      id: 5,
      options: categories,
      label: 'Categories',
      setItem: setSelectedCategory,
      multiple: false,
      optionsFilter: getCategories
    },
    {
      id: 6,
      // options: status,
      options: Object.values(RENAMED_STATUS_OPTIONS).map( value => ({id: value, name: value})),
      label: 'Status',
      setItem: setSelectedStatus,
      multiple: true,
      optionsFilter: filterStatus
    },
  ]


  const filterRender = (item) => {
    return (
    <MDBox sx={FilterItem} key={`idf-${item.id}`}>
      <Autocomplete
        disablePortal
        multiple={item?.multiple}
        options={item?.options}
        getOptionLabel={(option) => option.name}
        sx={{ minWidth: 100, width: '100%', ".MuiOutlinedInput-notchedOutline": { border: 'none' }}}
        renderInput={(params) =>
          <TextField {...params} label={item.label}
                     sx={{
                       '.MuiSvgIcon-root': {
                         color: '#0B7340',
                       },
                       '.MuiInputLabel-root': {
                         color: '#000',
                         paddingTop: '6px',
                         fontWeight: 500,
                       }}}
          />}
        onChange={(event, newValue) => {
          if (newValue) {
            item.setItem(newValue)
          } else {
            item.setItem(null)
          }
        }}
        onInputChange={(e) => {
          item.optionsFilter({search: e.target.value})
        }}
      />
    </MDBox>
  )}


  useEffect( () => {
    getCategories({search: ''})
    getManufacturers({search: ''})
    getOrders({search: ''})
    getCompanies({search: ''})
    getRegions({search: ''})
    getProjects({search: ''})
    getOrdersStatusAdmin({search: ''})
  }, [])

  useEffect(() => {
    getOrders(getFilters())
  }, [searchQuery, selectedManufacturer, selectedCategory, selectedCompany, selectedRegion, selectedProject, selectedStatus, orderingByOldNew, selectedOrderType])

  return (
    <AdminLayout
      title={'Order Management'}
    >
      <MDBox sx={styles.mainContainer}>
        <MDBox display={'flex'} justifyContent={'flex-end'} gap={2}>
          <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <MDButton
              color={currentPage === ORDER_PAGES.ORDER? "secondary" :'tertiary'}
              sx={{ width: '180px' }}
              onClick={() =>{
                setCurrentPage(ORDER_PAGES.ORDER)
                setSelectedOrderType([
                  {id: ORDER_PHASES.CONTRACT, name: ORDER_PHASES.CONTRACT},
                  {id: ORDER_PHASES.DELIVERY, name: ORDER_PHASES.DELIVERY,},
                  {id: ORDER_PHASES.FULFILLMENT, name: ORDER_PHASES.FULFILLMENT,},
                  {id: ORDER_PHASES.COMPLETE, name: ORDER_PHASES.COMPLETE,},
                  {id: ORDER_PHASES.REJECTED, name: ORDER_PHASES.REJECTED},
                ])
              }}
            >
              Order
            </MDButton>
          </MDBox>
          <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <MDButton
              color={currentPage === ORDER_PAGES.SHOPPING_CART? "secondary" :'tertiary'}
              sx={{ width: '180px' }}
              onClick={() => {
                setCurrentPage(ORDER_PAGES.SHOPPING_CART)
                setSelectedOrderType([
                  {id: ORDER_PHASES.COLLABORATION,  name: ORDER_PHASES.COLLABORATION,},
                  {id: ORDER_PHASES.QUOTE,  name: ORDER_PHASES.QUOTE,},
                ])
              }}
            >
              Shopping Cart
            </MDButton>
          </MDBox>
          <MDBox sx={InputContainer}>
            <TextField
              sx={Input}
              InputProps={{
                endAdornment:
                  <InputAdornment position="start">
                    <MDBox component={"img"} src={search} alt={"search"}/>
                  </InputAdornment>,
              }}
              onChange={(evt) => setSearchQuery(evt?.target?.value)}
              placeholder={'Search for an order'}
            />
          </MDBox>
          <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <MDButton
              color={'tertiary'}
              sx={{ width: '180px' }}
              onClick={() => setOrderingByOldNew(!orderingByOldNew)}
            >
              Order by {orderingByOldNew ? 'oldest' : 'newest'}
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox sx={Container}>
          {filterItems.map(item => filterRender(item))}
        </MDBox>
        {loading &&
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress color="inherit" size={35} />
          </Grid>
        }
        {orders.length > 0 ? orders.map((order) => {
          return (
            <OrderSummaryCard
              item={order}
              key={order.id}
              id={order.id}
              orderTotal={order.total}
              projectManager={order.project_manager}
              project={order.project}
              status={order.status}
              manufacturer={order.manufacturer}
              region={order.location}
              location={order.location}
              is_order={order.is_order}
            />
            )

          }) : !loading &&
          <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} height={'500px'}>
            <MDTypography variant={'subtitle1'}>No items found</MDTypography>
          </MDBox>
        }
        <Grid container mt={5}>
          <Grid item>
            <MDBox m={2} sx={{color: '#666666', fontSize: 17, width: 300}}>Showing <span
              style={{color: '#000000'}}>{paginationData.itemsPerPage}</span> from <span
              style={{color: '#000000'}}>{paginationData.counts}</span> data</MDBox>
          </Grid>
          <Grid item ml={'auto'}>
            <Pagination
              currentPage={paginationData.currentPage}
              totalCount={paginationData.counts}
              pageSize={10}
              onPageChange={page => {
                // page:page is to ensure that we have the current value in the filters
                getOrders({...getFilters(), page: page})
                setPaginationData(value => ({...value, currentPage: page}))
              }}
            />
          </Grid>
        </Grid>
      </MDBox>

    </AdminLayout>
  )
}

export default observer(Index);
const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    mr: pxToRem(20),
    gap: pxToRem(20),
    width: "100%",
    borderRadius: pxToRem(16)
  },
  filtersContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: pxToRem(16),
    width: "100%",
    height: pxToRem(62)
  },
  filter: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid #DBDBDB",
    width: "100%",
    gap: pxToRem(6)
  },
  filterText: {
    fontSize: pxToRem(14),
    fontWeight: 500,
    lineHeight: pxToRem(17),
  },
  searchContainer: {
    padding: pxToRem(10),
    width: "100%"
  },
  search: {
    border: "1px solid #DBDBDB",
    borderRadius: pxToRem(8),
    width: pxToRem(388)
  },
  filterIcon: {
    width: pxToRem(16),
    height: pxToRem(16),
  },
  arrowIcon: {
    width: pxToRem(30),
    height: pxToRem(30),
    color: "#0B7340"
  },
}
