import { observer } from "mobx-react";

// Components
import {OrdersAndQuotesBase} from "./orderQuoteBase";
import {BUTTON_OPTIONS, ORDER_PHASES} from "../../../services/constants";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export const ShoppingCart = observer(({selectedButtonId}) => {
  const location = useLocation()

  useEffect(() => {
    console.log(location)
  }, [location]);

  return (
    <OrdersAndQuotesBase statusList={[ORDER_PHASES.COLLABORATION, ORDER_PHASES.QUOTE]} selectedButtonId={selectedButtonId}/>
  );
});
