import {observer} from "mobx-react";
import {Box, Card, CircularProgress, Grid} from "@mui/material";

// Components
import DashboardProjectManagerLayout from "components/DashboardProjectManager";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";
import download from "assets/icons/download.svg";
import {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { useApi} from "../../../services/helpers";
import {ROLES, ROUTES} from "../../../services/constants";
import {useStores} from "../../../models";

function ProjectSelected({selectedButtonId}) {
  const api = useApi()
  const {id: projectId} = useParams()
  const rootStore = useStores()
  const {loginStore} = rootStore
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [project, setProject] = useState({categories: [], packages: [], drawings: []})
    const [searchParams ] = useSearchParams();
    const order_or_quote_id = searchParams.get('oqn');

  const getProjectDashboardInfo = (projectId) => {
    setLoading(true)
    api.getProjectDashboardInfo(projectId).then((result) => {
        setLoading(false)
      if (result.kind === 'ok') {
        setProject(result.data)
      }
    }).catch((reason) => {
      console.log(reason)
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleDrawingDownload = async (index, drawingId) => {
    setLoading(true)
    api.downloadBrandDrawing(projectId, drawingId).handle({
      onSuccess: (result) => {   result.download_file() },
      errorMessage: 'Error downloading drawing',
      onFinally: () => setLoading(false),
    })
  }


  useEffect(() => {
    if (!loginStore.isProjectManager && !loginStore.isSupplyManager && !loginStore.isSubContractorRep) {
      navigate(ROUTES.USER_PROJECT_SELECTOR)
    }
    getProjectDashboardInfo(projectId)
  }, [projectId])

  return (
    <DashboardProjectManagerLayout title={'Order By Category'} selectedButtonId={selectedButtonId}>
      <Grid container spacing={2} pt={3}>
        {loading &&
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress color="inherit" size={35} />
          </Grid>
        }
        {project.categories.length > 0 && project.categories.map((item, index) => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={`id_${index}`}>
            <Card
              onClick={() => navigate(ROUTES.PROJECT_PRODUCTS_BY_CATEGORY(projectId, item.id))}
              sx={{display: 'flex', flexDirection: { xs: 'column', lg: 'row'},alignItems: 'center', gap: pxToRem(21), p: pxToRem(21), borderRadius: '8px', cursor: 'pointer'}}
            >
              <Box component={'img'} src={item.logo} alt={'image'} width={pxToRem(85)} height={pxToRem(85)} sx={{objectFit:"contain"}}/>
              <MDTypography variant={'h5'} color={'tertiary'} fontSize={{ xs: '14px', mmd: '1.4vw', xl: '14px' }} sx={{display:'flex', flex: "1", wordBreak: "break-word", height:'97px', alignItems:'center'}}>{item.name} </MDTypography>
            </Card>
          </Grid>
        ))}
        {project.categories.length === 0 && !loading && (
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <MDTypography variant={'h5'} fontSize={'22px'}>No Categories Found</MDTypography>
          </Grid>
        )}
      </Grid>
      <MDTypography variant={'h2'} pt={5} pb={3}>Order By Package</MDTypography>
      <Grid container spacing={2}>
        {loading &&
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress color="inherit" size={35} />
          </Grid>
        }
        {project.packages.length > 0 && project.packages.map((item, index) => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={`key-${index}`}>
            <Card onClick={() => navigate(ROUTES.PACKAGE_DETAIL(projectId, item.id))} sx={{display: 'flex', flexDirection: { xs: 'column', lg: 'row'}, alignItems: 'center', gap: pxToRem(21), p: pxToRem(21), borderRadius: '8px', cursor: 'pointer'}}>
              <Box component={'img'} src={item.logo} alt={'image'} width={pxToRem(85)} height={pxToRem(85)} sx={{objectFit:"contain"}}/>
              <MDTypography variant={'h5'} color={'tertiary'} fontSize={{ xs: '14px', mmd: '1.4vw', xl: '14px' }}  sx={{ display:'flex', flex: "1", wordBreak: "break-word", height:'97px', alignItems:'center' }}>{item.name}</MDTypography>
            </Card>
          </Grid>
        ))}
        {project.packages.length === 0 && !loading && (
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <MDTypography variant={'h5'} fontSize={'22px'}>No Packages Found</MDTypography>
          </Grid>
        )}
      </Grid>
      {loginStore.group === ROLES.PROJECT_MANAGER.name &&
        <>
          <MDTypography variant={'h2'} pt={4} pb={4}>Drawings</MDTypography>
          <Card sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', p: pxToRem(21), width: '100%', borderRadius: '8px', mb: 3}}>
            <Grid container spacing={2}>
              {loading &&
                <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <CircularProgress color="inherit" size={35} />
                </Grid>
              }
              {project.drawings.length > 0 &&  project.drawings.map((item, index) => (
                <Grid item xs={12} md={6} lg={4} xl={3}
                      justifyContent={{xs: 'flex-start'}}
                      key={`drawing-${index}`}
                      sx={{cursor:'pointer'}}
                      onClick={async () => handleDrawingDownload(projectId, item.id)}
                >
                  <MDBox display='flex' flexDirection='column'>
                    <MDBox display={'flex'} justifyContent={{xs: 'center'}} alignItems={'flex-end'} gap={pxToRem(10)}>
                      <MDTypography variant={'textBold'} color={'tertiary'}>{item.name}</MDTypography>
                      <Box component={'img'} src={download} alt={'image'} width={pxToRem(24)}  />
                    </MDBox>
                    <MDBox display={'flex'} justifyContent={{xs: 'center'}} alignItems={'center'}>
                      <Box component={'img'} src={item.image ?? download} alt={'image'} width={pxToRem(236)} height={pxToRem(191)} sx={{objectFit:"contain"}}/>
                    </MDBox>
                  </MDBox>
                </Grid>
              ))}
              {project.drawings.length === 0 && !loading && (
                <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <MDTypography variant={'h5'} fontSize={'22px'}>No Drawings Found</MDTypography>
                </Grid>
              )}
            </Grid>
          </Card>
        </>
      }

    </DashboardProjectManagerLayout>
  );
}

export default observer(ProjectSelected);
