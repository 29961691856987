import {observer} from "mobx-react";
import {useParams} from "react-router-dom";


import {useEffect, useState} from "react";

import * as React from "react";
import {showMessage, useApi} from "../../../services/helpers";
import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import {Card, Icon} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {FilecardContainer, FilenameText} from "../../admin/manage-products/manage_product/styles";

export const ProgramDocumentList = observer(({selectedButtonId}) => {

  const api = useApi()
  const {id = null, categoryId = null, manufacturerId = null} = useParams();
  const [loading, setLoading] = useState(false);
  const [manufacturers, setManufacturers] = useState(false);

  const getManufacturer = () => {
    api.getManufacturerById( manufacturerId ).handle({
      onSuccess: (result) => {
        setManufacturers([result.data])
      },
      errorMessage: 'Error getting manufacturers',
    })
  }

  const getManufacturersByCategory = () => {
    api.getManufacturers({category_id: categoryId, project_id: id} ).then((result) => {
      if (result.kind === 'ok') {
        setManufacturers(result.data.results)
      }
    })
  }

  const downloadAllDocuments = (manufacturerId) => {
    setLoading(true)
    api.downloadManufacturerDocuments(manufacturerId).then((result) => {
      if (result.kind === 'ok') {
        result.download_file()
      }else{
        throw new Error()
      }
    }).catch((reason) => {
      showMessage()
    })
  }

  const downloadFile = (url) => {
    window.open(url, '_blank')
  }
  useEffect(() => {
    if(manufacturerId) getManufacturer()
    else if (categoryId) getManufacturersByCategory()
  }, [])

  return (
    <DashboardProjectManagerLayout
      title={'National Program Documents'}
      selectedButtonId={selectedButtonId}
    >
      <Card sx={styles.cardContainer}>

        {manufacturers?.length > 0 && manufacturers.map((manufacturer, index) => (
          <>
            <MDBox
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'start'}>
              <MDTypography sx={{
                ...styles.titles,
              }}
              >
                {`Documents from manufacturer ${manufacturer.name}`}
              </MDTypography>
              <MDButton
                variant="outlined"
                color="secondary"
                onClick={() => downloadAllDocuments(manufacturer.id)}
                disabled={manufacturer?.program_documents?.length <= 0}
              >
                Download All
              </MDButton>
            </MDBox>
            <MDBox
              display={'flex'}
              flexDirection={'column'}
              alignItems={'start'}>
              {manufacturer?.program_documents?.map((document) => (
                  <MDBox sx={FilecardContainer} key={document.id}>
                    <MDBox display='flex' alignItems='center'>
                      <Icon sx={(th) => ({ml: pxToRem(11), mr: 2})} fontSize='medium'>attachment</Icon>
                      <MDTypography sx={FilenameText}>
                        {document.file_name}
                      </MDTypography>
                    </MDBox>
                    <MDTypography
                      sx={{textDecoration: 'underline', mr: pxToRem(10), cursor: 'pointer'}}
                      variant='button'
                      fontWeight='regular'
                      color='tertiary'
                      onClick={() => downloadFile(document.program_document_file)}>
                      Download
                    </MDTypography>
                  </MDBox>
                )
              )}
            </MDBox>

            <MDBox
              display={'flex'}
              flexDirection={'column'}
              alignItems={'start'}>
            </MDBox>
          </>

        ))}

      </Card>
    </DashboardProjectManagerLayout>
  )
})


const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    mr: pxToRem(20),
    width: "100%",
    borderRadius: pxToRem(16)
  },
  cardContainer: {
    p: pxToRem(28)
  },
  titles: {
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(21),
    pt: pxToRem(20),
    pb: pxToRem(12),
  },
  cardStyle: {
    marginTop: pxToRem(20),
  }
}
