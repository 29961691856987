import React, {useEffect, useState} from 'react';
import {Box, Modal} from "@mui/material";
import MDBox from "../MDBox";
import closeIcon from "assets/icons/closeIcon.svg";
import checked from "assets/icons/checked-green.svg";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";
import {
  CartConfirmPackage, CartConfirmProduct
} from "../../pages/project-manager/orders-and-quotes/products-card-and-details/CartConfirmProduct";
import {useNavigate, useParams} from "react-router-dom";
import {ROUTES} from "../../services/constants";
import {money_fmt} from "../../services/helpers";
import {useStores} from "../../models";
import KeepShoppingModal from "../KeepShoppingModal";

export const CartConfirmationModal = ({addedInfo, openConfirmationModal, handleCloseConfirmationModal}) => {
  const rootStore = useStores()
  const navigate = useNavigate();
  const {loginStore} = rootStore
  const {id: projectId, idp, ido} = useParams();
  const [openKeepShoppingModal, setOpenKeepShoppingModal] = useState(false)

  const isProduct = !!addedInfo?.added?.manufacturer;
  const handleKeepShopping = () => {
    if (loginStore.isAdmin) {
      navigate(-1)
    } else {
      if (projectId) {
        if (loginStore.isSupplyManager){
          setOpenKeepShoppingModal(true)
        }
        else{ 
          if(addedInfo?.orders[0]?.manufacturer?.category){
            navigate(ROUTES.PROJECT_PRODUCTS_BY_CATEGORY(projectId, addedInfo?.orders[0]?.manufacturer?.category))
          }else{
            navigate(ROUTES.USER_PRODUCTS(projectId))
          }
        }
      }
    }
  }
  const handleViewQuote = (orderId) => {
    if (loginStore.isAdmin) {
      navigate(ROUTES.ADMIN_ORDER_MANAGEMENT_DETAIL(ido))
    } else {
      if (projectId && orderId) {
        navigate(ROUTES.USER_ORDERS_AND_QUOTES_DETAIL(projectId, orderId))
      }
    }
  }

  return (
    <>
      <KeepShoppingModal
        open={openKeepShoppingModal}
        handleClose={() => setOpenKeepShoppingModal(false)}
      />
      <Modal
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
      >
        <MDBox sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: 500,
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          border: 'none',
          boxShadow: 24,
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          flex: 1
        }}>
          <MDBox display={'flex'} justifyContent={'space-between'} borderBottom={'1px solid #DBDBDB'} p={2} pr={3}>
            <MDBox display={'flex'} gap={1}>
              <Box component={'img'} src={checked} alt={'checked'}/>
              <MDTypography variant={'h6'}>Added to
                Cart{(addedInfo && addedInfo?.orders?.length > 1) ? 's' : ''}</MDTypography>
            </MDBox>
            <Box component={'img'} src={closeIcon} alt={'close'} onClick={handleCloseConfirmationModal}/>
          </MDBox>
          <MDBox display={'flex'} justifyContent={'space-between'} p={2}>
            {/* here need to edit and add modal*/}
            <MDButton color={'primary'} onClick={handleKeepShopping}>Keep Shopping</MDButton>
          </MDBox>
          <MDBox borderBottom={'1px solid #DBDBDB'} pl={3}>
            {(addedInfo?.added && isProduct) &&
              <CartConfirmProduct
                item={addedInfo?.added.name}
                image={addedInfo?.added.main_img}
                shipping={addedInfo?.shipping}
                installation={addedInfo?.installation}
                model={addedInfo?.added.model}
                quantity={addedInfo?.qty}
                subtotal={addedInfo?.subtotal}
              />}
            {addedInfo?.added && !isProduct &&
              <CartConfirmPackage
                item={addedInfo?.added.name}
                image={addedInfo?.added.logo}
                quantity={addedInfo?.qty}
                subtotal={addedInfo?.subtotal}
              />}
          </MDBox>
          <MDBox borderBottom={'1px solid #DBDBDB'} display={'flex'} justifyContent={'start'} p={2}>
            <MDTypography
              variant={'subtitle2'}
              color={'tertiary'}
              fontWeight={'regular'}
            >Added to the following quote{(addedInfo && addedInfo?.orders?.length > 1) ? 's' : ''}:</MDTypography>
          </MDBox>
          <MDBox display={'flex'} justifyContent={'space-between'} flexDirection={'column'} p={2} sx={{overflow: 'auto'}}>
            {(addedInfo && addedInfo?.orders?.length) && addedInfo?.orders?.map((order, index) => (
              <MDBox display={'flex'} justifyContent={'space-between'} p={2}>
                <MDTypography variant={'h6'}>{order?.is_order ? 'Order' : 'Cart'}#{order.id}</MDTypography>
                <MDTypography variant={'h5'}>{money_fmt(order.total)}</MDTypography>
                <MDButton color={'secondary'} onClick={() => handleViewQuote(order.id)}>View Cart</MDButton>
              </MDBox>
            ))}
          </MDBox>
        </MDBox>
      </Modal>
    </>

  )
}

