import {Box, Checkbox, FormControlLabel, FormGroup, Grid} from "@mui/material";
import cart from "../../../../../assets/icons/cart-green.svg";
import MDTypography from "../../../../../components/MDTypography";
import check from "../../../../../assets/icons/check.svg";
import flecha from "../../../../../assets/icons/flecha.svg";
import contract_grey from "../../../../../assets/icons/contract-grey.svg";
import track from "../../../../../assets/icons/delivery-track.svg";
import checked from "../../../../../assets/icons/checked-grey.svg";
import * as React from "react";
import MDBox from "../../../../../components/MDBox";
import {ORDER_PHASES} from "../../../../../services/constants";

export const ShareQuoteSection = ({ type, current_status, tasks, requestedOrder }) => {

  const renderBox = (checked) => {
    if (checked) {
      return (
        <MDBox sx={{width: 18, height: 18, backgroundColor: '#00914C', border: '#00914C', borderRadius: 1,display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Box component={"img"} src={check} alt={""} sx={{ width: 18, height: 18 }}/>
        </MDBox>
      )
    } else {

      return (
        <MDBox sx={{minWidth: 18,  maxHeight: 18, backgroundColor: '#ffffff', border: '#00914C', borderRadius: 1, borderWidth: 2, borderStyle: 'solid'}} />
      )
    }

  }

  return (
    <>
      <MDBox borderRadius={'xl'} p={2} border={'2px solid #81D61E'}>
        <MDBox display={'flex'} gap={1} pb={1}>
          <Box component={"img"} src={cart} alt={"cart"} sx={{ width: 24, height: 24 }}/>
          <MDTypography variant={'h6'}>{"Shopping Cart"}</MDTypography>
        </MDBox>
        <Grid container pt={1} borderTop={'1px solid #DBDBDB'}>
          <Grid item xs={12}>
            <MDTypography pt={1} variant={'h6'}>Buyer's Tasks</MDTypography>
            <MDBox display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mt={1}>
              <MDTypography variant={'subtitle2'} fontSize={'13px'} ml={2}>
                Order Accepted
              </MDTypography>
              {/*{renderBox(current_status === ORDER_PHASES.COLLABORATION)}*/}
              {renderBox(tasks.approve_order || false)}
            </MDBox>
            {/*<MDBox display={'flex'} flexDirection={'row'} mt={1}>*/}
            {/*  {renderBox(tasks.is_order || false)}*/}
            {/*  <MDTypography variant={'subtitle2'} fontSize={'13px'} ml={2}>*/}
            {/*    Create Order*/}
            {/*  </MDTypography>*/}
            {/*</MDBox>*/}

            {/*<MDBox display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mt={1}>*/}
            {/*  <MDTypography variant={'subtitle2'} fontSize={'13px'} ml={2}>*/}
            {/*    Review/Approve Quote*/}
            {/*  </MDTypography>*/}
            {/*  {renderBox(tasks.approve_order || false)}*/}
            {/*</MDBox>*/}


                {/*<FormControlLabel*/}
                {/*    control={<Checkbox disabled checked={tasks.quote_shared || false}  sx={{ '.MuiSvgIcon-root': { color: '#00914C', border: '#00914C'} }} />}*/}
                {/*    label={*/}
                {/*        <MDTypography variant={'subtitle2'} fontSize={'13px'}>*/}
                {/*            Share Quote*/}
                {/*        </MDTypography>}*/}
                {/*/>*/}

          </Grid>
          <Grid item xs={12}>
            <MDTypography pt={1} variant={'h6'}>Vendor's Tasks</MDTypography>
            <MDBox display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mt={1}>
              <MDTypography variant={'subtitle2'} fontSize={'13px'} ml={2}>
                Order Accepted
              </MDTypography>
              {renderBox(tasks.vendor_approve_order || false)}
            </MDBox>

          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <MDTypography pt={1} variant={'h6'}>Buyer's Tasks</MDTypography>*/}
          {/*  <MDBox display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mt={1}>*/}
          {/*    <MDTypography variant={'subtitle2'} fontSize={'13px'} ml={2}>*/}
          {/*      Edit or Approve Final Order*/}
          {/*    </MDTypography>*/}
          {/*    {renderBox(tasks.approve_order || false)}*/}
          {/*  </MDBox>*/}
          {/*</Grid>*/}
        </Grid>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{ width: 24, height: 24 }}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2} border={'2px solid #DBDBDB'} sx={{ height: '58px' }}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={contract_grey} alt={"contract_grey"} sx={{ width: 24, height: 24 }}/>
          <MDTypography variant={'h6'}>Contract</MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{ width: 24, height: 24 }}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2} border={'2px solid #DBDBDB'} sx={{ height: '58px' }}>
          <MDBox display={'flex'} alignItems={'center'} gap={1}>
            <Box component={"img"} src={track} alt={"track"} sx={{ width: 24, height: 24 }} />
            <MDTypography variant={'h6'}>Fulfillment</MDTypography>
          </MDBox>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
          <Box component={"img"} src={flecha} alt={"flecha"} sx={{ width: 24, height: 24 }}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2} border={'2px solid #DBDBDB'} sx={{ height: '58px' }}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={checked} alt={"checked"} sx={{ width: 24, height: 24 }}/>
          <MDTypography variant={'h6'}>Complete</MDTypography>
        </MDBox>
      </MDBox>
    </>
  )
}
