import { useCallback, useEffect, useState} from "react";
import {Icon} from "@mui/material";
import {observer} from "mobx-react";
import AddBaseModal from "../../../../../components/add-base-modal/AddBaseModal";
import MDTypography from "../../../../../components/MDTypography";
import debounce from "lodash.debounce";
import { useApi } from "../../../../../services/helpers";
import { ROLES } from "../../../../../services/constants";
import { DoneText, NewPMButton } from "../../../../admin/manage-projects/styles";
import ConfirmDialogModal from "../../../../../components/ConfirmDialogModal";
import MDBox from "../../../../../components/MDBox";
import MDInput from "../../../../../components/MDInput";
import CustomerCell from "../../../../../layouts/ecommerce/orders/order-list/components/CustomerCell";
import MDButton from "../../../../../components/MDButton";
import pxToRem from "../../../../../assets/theme/functions/pxToRem";

export const UpdateManagerModal = observer(({open, order, currentPM, onClose, onUpdate}) => {
  const api = useApi()
  const [managers, setManagers] = useState([])
  const [searchValue, setSearchValue] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const getUsers = ({search}) => {
    setLoading(true)
    api.getAllUsers({
      search: search,
      page: 1,
      ordering: '',
      page_size: 25,
      user_role: `${ROLES.PROJECT_MANAGER.name},${ROLES.SUB_CONTRACTOR_REP.name}`,
    }).handle({
      onSuccess: (result) => { setManagers(result.data.results) },
      errorMessage: 'Error getting managers',
      onFinally: setLoading(false)
    })
  }

  const searchUser = (value) => {
    getUsers({search: value})
  }

  const debouncedInputChange = useCallback(debounce(searchUser, 300), [])

  const UpdateProjectManger = () => {
    setLoading(true)
    api.updateOrderProjectManager(order.id, {buyer_id: selectedUser.id,}).handle({
      onSuccess: (res) => {
        onUpdate(res?.data)
        onClose()
      },
      successMessage: 'Buyer updated successfully',
      errorMessage: 'Error updating buyer',
      onFinally: () => {
        setOpenModal(false)
        setLoading(false)
      },
    })
  }

  useEffect(() => {
    getUsers({search: ""})
  }, [])

  return (
    <AddBaseModal
      open={open}
      loading={loading}
      handleClose={() => {
        onClose()
      }}
      title={'Update Buyer'}
      headerContent={
        <MDTypography
          variant='h6'
          sx={DoneText}
          onClick={() => {
            onClose()
          }}
        >
          Done
        </MDTypography>
      }
      showActions={false}
      modalContainer={{height: pxToRem(820)}}
    >
      <ConfirmDialogModal
        title={'Do you want to update the current buyer?'}
        description={``}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={() => UpdateProjectManger()}
      />

      <MDBox padding={`${pxToRem(20)} ${pxToRem(24)}`} overflow={'auto'}>
        <MDInput
          sx={{width: '100%'}}
          placeholder={'Search for users'}
          value={searchValue}
          onChange={(e) => {
            debouncedInputChange(e.target.value)
            setSearchValue(e.target.value);
          }}
          InputProps={{
            endAdornment:
              <Icon fontSize='medium' sx={({palette: {icons}}) => ({color: icons.search_icon})}>
                search
              </Icon>
          }}
        />
        <MDBox display='flex' flexDirection='column' gap={pxToRem(28)}>
          {!managers.length
            ? <MDBox height={'200px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <MDTypography variant='subtitle2'>No managers found</MDTypography>
            </MDBox>
            : managers.map(user => (
              <MDBox key={user.id} border='none' display='flex' justifyContent='space-between' alignItems='center'>
                <CustomerCell
                  name={user.name}
                  image={user.profile_picture}
                  color={user.color || "dark"}
                  styles={{fontSize: '1rem'}}
                />
                <MDButton
                  variant={user.id === currentPM.id ? "text" : "outlined"}
                  disabled={user.id === currentPM.id}
                  color={"secondary"}
                  onClick={() => {
                    setOpenModal(true)
                    setSelectedUser(user)
                  }}
                  sx={NewPMButton}
                >
                  {user.id === currentPM.id ? 'Current' : 'Choose'}
                </MDButton>
              </MDBox>
            ))}
        </MDBox>
      </MDBox>
    </AddBaseModal>
  )
})
